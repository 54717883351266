import { Box, Typography } from '@mui/material';
import { isNumber } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Outlet, useMatch, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
    getCatalogItems,
    getSelectedCatalogItems,
} from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { CatalogItem } from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { fetchCategories } from 'entities/Category';
import { fetchOrder } from 'entities/Order';
import {
    OrderStatuses,
    getOrderStatusForRoute,
    statusMapping,
    useGetOrderStatusTitle,
} from 'entities/Order/model/consts/orderStatuses';
import {
    getSelectedOrder,
    getSelectedOrderPropertyId,
    getSelectedOrderStatus,
} from 'entities/Order/model/selectors/ordersSelectors';
import { fetchMetaInfo } from 'entities/Order/model/services/fetchMetaInfo/fetchMetaInfo';
import { useFetchProperties } from 'entities/Property/model/lib/useFetchProperties';
import { propertyActions } from 'entities/Property/model/slices/PropertySlice';
import { UserRole, getUserRole } from 'entities/User';
import {
    fetchPropertyVendorsList,
    getIsLoadingVendorsList,
    getPropertyVendorsList,
} from 'entities/Vendors';
import {
    getProposalFilterIsActive,
    getProposalFilteredItems,
} from 'features/OrderProposalFilter/model/selectors/orderProposalFilteSelectors';
import { getPropertyCatalogId } from 'features/fetchPropertyById/model/selectors/fetchPropertyByIdSelectors';
import { fetchPropertyById } from 'features/fetchPropertyById/model/services/fetchPropertyById/fetchPropertyById';
import { getGroupSummary } from 'pages/OrderViewPage/lib/groupSummary/groupSummary';
import {
    getRouteNotFound,
    getRouteOrdersTableByStatus,
    getRouteOrdersViewItemDetailed,
} from 'shared/const/router';
import { getPrice } from 'shared/lib/getPrice/getPrice';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { groupBy } from 'shared/lib/lodash/lodash';
import { PageTitle } from 'shared/ui/PageTitle/PageTitle';
import RemoveOrderModal from 'shared/ui/RemoveOrderModal/RemoveOrderModal';
import { Tooltip } from 'shared/ui/Tooltip';
import { deleteOrder } from 'widgets/OrdersTable/model/services/deleteOrder/deleteOrder';
import { Page } from 'widgets/Page';

import { OrderViewPageInfo } from './OrderViewPageInfo';
import { OrderViewPageItems } from './OrderViewPageItems';

export const OrderViewPage = memo(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const params = useParams();
    const fetchProperties = useFetchProperties();

    const [orderLoaded, setOrderLoaded] = useState(false);

    const currentUserRole = useSelector(getUserRole);
    const catalogItems = useSelector(getCatalogItems);
    const filteredCatalogItems = useSelector(getProposalFilteredItems);
    const proposalFilterIsActive = useSelector(getProposalFilterIsActive);
    const propertyId = useSelector(getSelectedOrderPropertyId);
    const currentOrder = useSelector(getSelectedOrder);
    const currentOrderStatus = useSelector(getSelectedOrderStatus);
    const propertyCatalogId = useSelector(getPropertyCatalogId);
    const selectedItems = useSelector(getSelectedCatalogItems);

    const vendorsList = useSelector(getPropertyVendorsList);

    const catalogItemsToDisplay = proposalFilterIsActive
        ? filteredCatalogItems
        : catalogItems;

    const orderId = params?.orderId;

    const isDraftTab = currentOrderStatus === 'DRAFT';
    const orderNumber = currentOrder?.orderNumber || '';
    const [isShowDialog, setIsShowDialog] = useState(false);
    const [isLoadingDeleteOrder, setIsLoadingDeleteOrder] = useState(false);

    const currentOrderStatusTitle = useGetOrderStatusTitle(currentOrderStatus);

    const isTechnicianUser =
        currentUserRole === UserRole.MAINTENANCE_TECHNICIAN;

    const onBack = useCallback(() => {
        let statusForBack;
        if (statusMapping['PROCESSING'].includes(currentOrderStatus)) {
            statusForBack = OrderStatuses.PROCESSING;
        } else if (statusMapping['INVOICED'].includes(currentOrderStatus)) {
            statusForBack = OrderStatuses.INVOICED;
        } else if (
            statusMapping['UNABLE_TO_FULFILL'].includes(currentOrderStatus)
        ) {
            statusForBack = OrderStatuses.UNABLE_TO_FULFILL;
        } else {
            statusForBack = currentOrderStatus;
        }
        navigate(
            getRouteOrdersTableByStatus(
                propertyId,
                getOrderStatusForRoute(statusForBack),
            ),
        );
    }, [currentOrderStatus, navigate, propertyId]);

    const redirectOnNotFound = useCallback(
        () => navigate(getRouteNotFound()),
        [navigate],
    );

    const initialOrderLoad = useCallback(async () => {
        await fetchProperties(true);
        await dispatch(
            fetchOrder({ id: orderId, onNotFound: redirectOnNotFound }),
        );
        await dispatch(
            fetchMetaInfo({
                id: orderId,
            }),
        );
        setOrderLoaded(true);
    }, [dispatch, orderId, fetchProperties, redirectOnNotFound]);

    useEffect(() => {
        initialOrderLoad();
    }, [initialOrderLoad]);

    const initProperty = useCallback(async () => {
        const response = await dispatch(fetchPropertyById(propertyId));

        if (typeof response.payload !== 'string') {
            const catalogId = response.payload.catalogId;
            await dispatch(fetchCategories(catalogId));
        }

        dispatch(propertyActions.setPropertyId(propertyId));
    }, [dispatch, propertyId]);

    const vendorsLoading = useSelector(getIsLoadingVendorsList);

    useEffect(() => {
        if (propertyId) {
            dispatch(fetchPropertyVendorsList(propertyId));
        }
        if (currentOrder?.id) {
            initProperty();
        }
    }, [currentOrder?.id, initProperty, dispatch, propertyId]);

    useEffect(() => {
        if (propertyCatalogId) {
            dispatch(fetchCategories(propertyCatalogId));
        }
    }, [dispatch, propertyCatalogId]);

    const pageTitle = `${t('Order')} ${currentOrder?.orderNumber || ''}`;

    const groupedItems = useMemo(
        () => groupBy(catalogItemsToDisplay, 'categoryId'),
        [catalogItemsToDisplay],
    ) as Record<string, CatalogItem[]>;

    const staticGroupedItems = useMemo(
        () => groupBy(catalogItems, 'categoryId'),
        [catalogItems],
    ) as Record<string, CatalogItem[]>;

    const summaryByCategories = useMemo(
        () => getGroupSummary(staticGroupedItems, selectedItems),
        [staticGroupedItems, selectedItems],
    );

    const isDetailedItem = useMatch(
        getRouteOrdersViewItemDetailed(':orderId', ':itemId'),
    );

    const closeDialog = useCallback(() => {
        setIsShowDialog(false);
    }, []);

    const openDialog = useCallback(() => {
        setIsShowDialog(true);
    }, []);

    const deleteOrderFromList = useCallback(async () => {
        setIsLoadingDeleteOrder(true);
        await dispatch(deleteOrder(orderId));
        toast(t('The order has been deleted'));
        setIsLoadingDeleteOrder(false);
        setIsShowDialog(false);
        onBack();
    }, [dispatch, orderId, onBack, t]);

    const pricingRow = useCallback(
        (
            label: string,
            value: string,
            isBold?: boolean,
            showTooltip?: boolean,
        ) => (
            <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                    py: '10px',
                }}
                data-testclass="attribute"
            >
                <Typography
                    color="#00000080"
                    typography={'openSans.body2'}
                    data-testclass="label"
                >
                    {label}
                </Typography>
                <Box display="flex" alignItems="center" gap={'5px'}>
                    <Typography
                        typography={
                            isBold
                                ? 'openSans.subtitle1Medium'
                                : 'openSans.body2'
                        }
                        style={{ fontWeight: isBold ? 700 : 400 }}
                        data-testclass="value"
                    >
                        {value}
                    </Typography>
                    {showTooltip && (
                        <Tooltip
                            isSecondaryColor
                            content={t(
                                'Total price cannot be calculated because some item prices from suppliers are unavailable.',
                            )}
                        />
                    )}
                </Box>
            </Box>
        ),
        [t],
    );

    const totalPrice = useMemo(
        () => getPrice(currentOrder?.totalPrice),
        [currentOrder],
    );

    return (
        <Page bgColor="transparent" padding="0">
            <Box display="flex" gap="16px" height={'calc(100vh - 112px)'}>
                <Box
                    bgcolor="background.paper"
                    borderRadius="16px"
                    p="16px"
                    display="flex"
                    flexDirection="column"
                    flex="2"
                    sx={{ overflowY: 'scroll' }}
                >
                    <Box
                        display="flex"
                        width="100%"
                        justifyContent="space-between"
                    >
                        <Box>
                            <PageTitle
                                customAtts={{ 'data-testid': 'order-number' }}
                                editTitle={pageTitle}
                                backTitle={t('Orders')}
                                onBack={onBack}
                                isEditing
                                isDraftTab={isDraftTab}
                                isShowDialog={isShowDialog}
                                openDialog={openDialog}
                                isLoading={!orderLoaded}
                            />
                        </Box>
                        {orderLoaded && (
                            <Typography
                                data-testid="order-status"
                                alignSelf="flex-end"
                                typography="openSans.subtitle1Medium"
                                mb="28px"
                            >
                                {currentOrderStatusTitle}
                            </Typography>
                        )}
                    </Box>

                    {currentOrder && (
                        <OrderViewPageInfo
                            summaryByCategories={summaryByCategories}
                            isLoading={!orderLoaded}
                            order={currentOrder}
                        />
                    )}
                    {currentOrder && orderLoaded && !isTechnicianUser && (
                        <Box
                            sx={{ marginTop: 'auto' }}
                            data-testid="order-pricing-details"
                        >
                            {pricingRow(
                                t('Subtotal'),
                                getPrice(currentOrder.price),
                            )}
                            <Box
                                sx={{ height: '1px', background: '#E9E8E8' }}
                            />
                            {pricingRow(
                                t('Shipping'),
                                currentOrderStatus === 'INVOICED'
                                    ? isNumber(currentOrder.shippingPrice)
                                        ? `$${currentOrder.shippingPrice.toFixed(
                                              2,
                                          )}`
                                        : t('Unavailable')
                                    : isNumber(currentOrder.shippingPrice) &&
                                      currentOrder.shippingPrice > 0
                                    ? `$${currentOrder.shippingPrice.toFixed(
                                          2,
                                      )}`
                                    : t('Determined by supplier'),
                            )}
                            <Box
                                sx={{ height: '1px', background: '#E9E8E8' }}
                            />
                            {pricingRow(
                                t(
                                    currentOrder.status.toLowerCase() ===
                                        'invoiced'
                                        ? 'Tax'
                                        : 'Estimated tax',
                                ),
                                getPrice(currentOrder.tax),
                            )}
                            <Box
                                sx={{ height: '1px', background: '#000000' }}
                            />
                            {pricingRow(
                                t(
                                    currentOrder.status.toLowerCase() ===
                                        'invoiced'
                                        ? 'Total'
                                        : 'Estimated total',
                                ),
                                totalPrice,
                                true,
                                totalPrice === 'Unavailable',
                            )}
                        </Box>
                    )}
                </Box>
                <Box bgcolor="background.paper" borderRadius="16px" flex="3">
                    {!isDetailedItem && orderLoaded && !vendorsLoading ? (
                        <OrderViewPageItems
                            vendors={vendorsList}
                            groupedItems={groupedItems}
                            onBack={onBack}
                        />
                    ) : (
                        orderLoaded && <Outlet />
                    )}
                </Box>
            </Box>
            <RemoveOrderModal
                isShowDeleteOrder={isShowDialog}
                title={t('Delete Order')}
                text={`Are you sure you want to delete this order? This action cannot be undone.`}
                deleteButtonText={`Delete order`}
                isLoadingDeleteOrder={isLoadingDeleteOrder}
                onCloseDeleteOrder={closeDialog}
                onSubmitDeleteOrder={deleteOrderFromList}
            />
        </Page>
    );
});
