import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { OrderStatuses } from 'entities/Order/model/consts/orderStatuses';
import {
    getInteractionStatus,
    getInvoiceStatus,
    getShipmentStatus,
} from 'entities/Order/model/selectors/ordersSelectors';
import { Order } from 'entities/Order/model/types/OrderSchema';

import { OrderInvoiceDetails } from './OrderInvoiceDetails';
import { OrderShipmentDetails } from './OrderShipmentDetails';
import { OrderStatusDetails } from './OrderStatusDetails';
import { OrderViewPageDetails } from './OrderViewPageDetails';
import { OrderViewSummaryDetails } from './OrderViewSummaryDetails';

interface OrderViewPageInfoProps {
    summaryByCategories: Record<
        string,
        {
            count: number;
            cost: number;
        }
    >;
    isLoading: boolean;
    order: Order;
}

export const OrderViewPageInfo = ({
    summaryByCategories,
    isLoading,
    order,
}: OrderViewPageInfoProps) => {
    const interactionStatus = useSelector(getInteractionStatus);
    const shipmentStatus = useSelector(getShipmentStatus);
    const invoiceStatus = useSelector(getInvoiceStatus);

    return (
        <Box sx={{ mb: '16px' }}>
            {!isLoading && (
                <>
                    <OrderViewPageDetails />
                    <OrderViewSummaryDetails
                        isLoadingSummaryDetails={isLoading}
                        summaryByCategories={summaryByCategories}
                        isInteractionStatus={!!interactionStatus}
                    />
                    {![
                        OrderStatuses.DRAFT,
                        OrderStatuses.IN_REVIEW,
                        OrderStatuses.PENDING_APPROVAL,
                    ].includes(order.status) && (
                        <>
                            {interactionStatus && (
                                <OrderStatusDetails
                                    interactionStatus={interactionStatus}
                                />
                            )}
                            {shipmentStatus && (
                                <OrderShipmentDetails
                                    shipmentStatus={shipmentStatus}
                                />
                            )}
                            {invoiceStatus && (
                                <OrderInvoiceDetails
                                    invoiceStatus={invoiceStatus}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </Box>
    );
};
